export default {
  APP_NAME: 'Walter-Inscription',
  CRM_URL: {
    PRODUCTION: 'https://api.crm.walter-learning.com',
    STAGING: 'https://api.crm-stg.walter-learning.com',
    // DEVELOPMENT: 'http://localhost:8000',
    DEVELOPMENT: 'https://api.crm-stg.walter-learning.com',
  },
  SSO_URL: {
    PRODUCTION: 'https://walter-learning-adtest.awsapps.com/start#/saml/default/Walter%20CRM/ins-dfa828bc8dc482e1',
    STAGING: 'https://walter-learning-adtest.awsapps.com/start#/saml/default/Walter%20CRM/ins-fb9dc55fcbcdf8ef',
    DEVELOPMENT:
      'https://walter-learning-adtest.awsapps.com/start#/saml/default/Walter%20CRM%20(Local)/ins-93b8714ba0fea4eb',
  },
}
