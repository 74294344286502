import { useEffect, useState } from 'react'
import {
  ApiCreateRespType,
  FormType,
  NormalFormData,
  ParamsType,
  ProfessionType,
  SessionType,
} from '../utils/sharedTypes'
import { FormDefaultState } from '../utils/constants'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  Alma,
  Financement,
  Information,
  Recap,
  Recommandation,
  DPCRegistration,
  PreInscription,
  Stripe,
} from '../pages'

export default function Registration() {
  const [params, setParams] = useState<ParamsType>({})
  const [form, setForm] = useState<FormType>(FormDefaultState)

  useEffect(() => {
    if (params.global_id) {
      client
        .get(`/registration/${params.global_id}/`)
        .then((resp) => {
          const data = resp.data as NormalFormData
          setForm((prevState) => {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                ...data,
                alma_installments_count: data.personal_funding ? 1 : data.alma_installments_count,
              },
            }
          })
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }, [params.global_id])

  useEffect(() => {
    if (params.product_code && !params.global_id) {
      //INIT INSCRIPTION
      const payload = {
        entrypoint_url: decodeURIComponent(params.entrypoint_url ?? ''),
        product: {
          product_code: params.product_code.toLowerCase(),
        },
      }
      client
        .post('/registration/', payload)
        .then((resp) => {
          const data = resp.data as ApiCreateRespType
          setForm((prevState) => {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                global_id: data.global_id,
                product: data.product,
                theme: data.theme,
              },
            }
          })
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })

      //GET PROFESSIONS
      client
        .get(`/registration/content/professions/?product=${params.product_code.toLowerCase()}`)
        .then((resp) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = resp.data.results as ProfessionType[]
          let reorderedData = data
          const indexOfOther = data?.findIndex((p) => p.code === 'unknown')
          if (indexOfOther !== -1) {
            reorderedData = [...data.slice(0, indexOfOther), ...data.slice(indexOfOther + 1), data[indexOfOther]]
          }

          setForm((prevState) => {
            return {
              ...prevState,
              professions: reorderedData,
            }
          })
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }, [params])

  useEffect(() => {
    if (
      form.data.product?.product_code &&
      form.data.funding_organism?.code &&
      form.data.profession?.code &&
      form.data.funding_organism?.code === 'FIFPL'
    ) {
      client
        .get(
          `/registration/content/sessions/?product_code=${form.data.product.product_code.toLowerCase()}&funding_organism=${form.data.funding_organism.code.toLowerCase()}&profession=${form.data.profession.code.toLowerCase()}`,
        )
        .then((resp) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = resp.data.results as SessionType[]
          setForm((prevState) => {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                session:
                  prevState.data.session?.global_id || data.length === 0
                    ? prevState.data.session
                    : { global_id: data[0].global_id },
              },
              sessions: data,
            }
          })
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }, [form.data.product?.product_code, form.data.funding_organism?.code, form?.data?.profession?.code])

  useEffect(() => {
    const searchObj = window.location.search ? Object.fromEntries(new URLSearchParams(window.location.search)) : {}
    setParams(searchObj)
    setForm((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          utm_campaign: searchObj.utm_campaign || null,
          utm_source: searchObj.utm_source || null,
          utm_medium: searchObj.utm_medium || null,
          utm_content: searchObj.utm_content || null,
          utm_term: searchObj.utm_term || null,
          adset_id: searchObj.adset_id || null,
          entrypoint_url: decodeURIComponent(searchObj.entrypoint_url) || null,
        },
      }
    })
  }, [])
  return (
    <Routes>
      <Route path="/information" element={<Information form={form} setForm={setForm} />} />
      <Route path="/financement" element={<Financement form={form} setForm={setForm} />} />
      <Route path="/pre-inscription" element={<PreInscription form={form} setForm={setForm} />} />
      <Route path="/recapitulatif" element={<Recap form={form} setForm={setForm} />} />
      <Route path="/alma" element={<Alma form={form} />} />
      <Route path="/stripe" element={<Stripe />} />
      <Route path="/recommandation" element={<Recommandation form={form} />} />
      <Route path="inscription_dpc" element={<DPCRegistration form={form} setForm={setForm} />} />
      <Route path="*" element={<Navigate to="information" replace />} />
    </Routes>
  )
}
